/* You can add global styles to this file, and also import other style files */
body {
  margin: 0;
  zoom: 1;
  font-weight: 500;
  background-color: white;
  font-family: 'Montserrat';
  color: black;
}

html, body {
  height: 100%;
}

h1 {
  font-size: 21px;
}

h2 {
  font-size: 18px;
}

h3 {
  font-size: 16px;
}

h4 {
  font-size: 13px;
}

h5 {
  font-size: 12px;
}

h6 {
  font-size: 11px;
}

/* Absolute Center Spinner */
.loading-indicator {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  height: 100vh;
  top: calc(50vh - 4em);
  left: calc(50vw - 3.75em);
}

.loading-indicator i {
  font-size: 48px;
  color: #4D5FD1;
}

/* Transparent Overlay */
.loading-indicator:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(231, 231, 231, 0.15);
}
